<template>
  <div class="sm:c-col-4 md:c-col-8 lg:c-col-6">
    <p-form
      ref="form"
      @submit="onFormSubmit"
      v-slot="{ invalid }"
    >
      <!-- Título -->
      <div class="c-row">
        <div class="c-col">
          <div class="c-text-subtitle">
            Estamos quase lá!
          </div>
        </div>
      </div>

      <!-- Subtítulo -->
      <div class="c-row ui-mb-4">
        <div class="c-col">
          <div class="c-text-b2">
            Não conseguimos encontrar seu email, informe-o abaixo para continuar.
          </div>
        </div>
      </div>

      <!-- Email -->
      <div class="c-row ui-mb-4">
        <validation-provider
          class="c-col"
          rules="required|email"
          name="Email"
          v-slot="{ errors }"
          vid="email"
        >
          <p-input
            ref="emailField"
            v-model="email"
            data-cy="emailField"
            label="Email"
            type="email"
            :error-message="errors[0]"
          />
        </validation-provider>
      </div>

      <separator />

      <!-- Botões -->
      <div class="c-row ui--mb-4">
        <!-- Continuar -->
        <div class="c-col-2 sm:c-col-1/2 ui-mb-4">
          <c-button
            data-cy="registerButton"
            size="lg"
            type="submit"
            class="ui-w-full"
            :disabled="invalid"
            :loading="isFormSending"
          >
            Continuar
          </c-button>
        </div>

        <!-- Voltar -->
        <div class="c-col-2 sm:c-col-1/2 ui-mb-4 c-d-flex ui-self-center ui-justify-center">
          <c-link
            @click="$router.go(-1)"
            class="c-text-hyperlink c-text-gray"
          >
            Voltar
          </c-link>
        </div>
      </div>
    </p-form>
  </div>
</template>

<script>
import { CButton, CLink } from '@estrategiahq/coruja-web-ui';
import Separator from '@/components/separator';
import PForm from '@/components/p-form';
import PInput from '@/components/p-input';
import { userAlreadyExistsError } from '@/utils/errors';
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    PForm,
    PInput,
    CButton,
    CLink,
    Separator,
    ValidationProvider,
  },
  data: () => ({
    email: '',
    isFormSending: false,
  }),
  props: {
    providerName: {
      type: String,
      default: '',
    },
    providerToken: {
      type: String,
      default: '',
    },
  },
  beforeMount() {
    if (this.providerToken === '' || this.providerName === '') {
      this.$router.push({ name: 'login', query: this.$route.query });
    }
  },
  methods: {
    async onFormSubmit() {
      this.isFormSending = true;

      try {
        await this.$services.accounts.socialLoginRegister(this.providerName, this.providerToken, this.email);
        this.$router.push({
          name: 'cadastro-confirme-seu-email',
          query: {
            ...this.$route.query,
            email: this.email,
          },
        });
      } catch (e) {
        if (e === userAlreadyExistsError) {
          this.$toast.show({ type: 'error', text: 'Erro ao realizar cadastro' });
          this.$refs.emailField.setErrorState();
          this.$refs.form.setErrors({
            email: ['Email já cadastrado'],
          });
          return;
        }
        throw e;
      } finally {
        this.isFormSending = false;
      }
    },
  },
};
</script>
